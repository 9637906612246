const fakeCurrData = {
    USD: {
        BTC: 0.000048,
        EUR: 0.927132,
        RUB: 68.765714,
        USD: 1,
        STBL: 1.008178,
        ATT: 0.000652
    },
    EUR: {
        BTC: 0.000052,
        EUR: 1,
        RUB: 74.170342,
        USD: 1.078595,
        STBL: 1.087416,
        ATT: 0.000704
    },
    RUB: {
        BTC: 0.000001,
        EUR: 0.013482,
        RUB: 1,
        USD: 0.014542,
        STBL: 0.014661,
        ATT: 0.000009
    },
    BTC: {
        BTC: 1,
        EUR: 19241.096154,
        RUB: 1427118.673077,
        USD: 20753.346154,
        STBL: 20923.076923,
        ATT: 13.538462
    },
    ATT: {
        BTC: 0.073864,
        EUR: 1421.21733,
        RUB: 105412.174716,
        USD: 1532.917614,
        STBL: 1545.454545,
        ATT: 1
    },
    STBL: {
        BTC: 0.000048,
        EUR: 0.919611,
        RUB: 68.207878,
        USD: 0.991888,
        STBL: 1,
        ATT: 0.000647
    }
}