function mobileMenu() {
    const menu = {
        burgerBtn: document.querySelector(".menu-burger__btn"),
        menu: document.querySelector(".menu"),
        overlay: document.querySelector(".menu__overlay"),
        list: document.querySelector(".menu__list"),

        init() {
            this.burgerBtn.checked = false;
            this.burgerBtn.addEventListener("click", () => {
                this.toggle();
            });
            this.overlay.addEventListener("click", () => {
                this.burgerBtn.checked = false;
                this.toggle();
            });
            this.list.addEventListener("click", (event) => {
                if (event.target.classList.contains("menu__item-link")) {
                    this.burgerBtn.checked = false;
                    this.toggle();
                }
            });
        },

        toggle() {
            this.menu.classList.toggle("menu_active");
            this.overlay.classList.toggle("menu__overlay--active");
        },
    };

    menu.init();
}